<template>
    <div id="form">
        <h1 
        v-motion
                :initial="{
                  y: -100,
                  opacity: 0,
                }"
                :visible="{
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 25,
                  },
                }"
        class="text-4xl sm:text-5xl xl:text-6xl py-5 font-semibold text-custom-gray flex text-center justify-center half-border-bottom-centered relative">
           للإستفسار او حجز المواعيد      
        </h1>
        <form
        @submit.prevent="sendEmail"
          class="flex flex-col gap-20 justify-center items-center pt-10">
            <div
            v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 25,
                  },
                }"
            class="flex flex-row gap-3">
                <p class="text-2xl text-custom-gray">05059595050</p>
                <img class="w-5" src="@/assets/icons/phoneIcon.svg" alt="phone icon">
            </div>
            <div
            v-motion
                :initial="{
                  opacity: 0,
                }"
                :visible="{
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 100,
                  },
                }"
            class="flex flex-col gap-3 w-full xl:w-[690px] max-w-[690px] px-5">
                <input 
                v-model="email"
                class="border border-black-100 p-5 focus:outline focus:outline-blue-400 transition-outline" type="email" name="email" placeholder="الايميل">
                <input 
                v-model="subject"
                class="border border-black-100 p-5 focus:outline focus:outline-blue-400" type="text" name="message" placeholder="العنوان">
                <textarea 
                v-model="content"
                placeholder="الرسالة" rows="10" class="border border-black-100 p-5 focus:outline focus:outline-blue-400"></textarea>
                <div class="pt-5 mx-auto">
                  <button
                  class="bg-custom-blue self-center py-2 px-12 text-white text-xl rounded-xl" type="submit">
                      إرسال
                  </button>
                </div>
            </div>
        </form>
        <TransitionRoot
        :show="isOpen"
        as="template"
        enter="duration-300 ease-out transform scale-100"
        enter-from="opacity-0 scale-95"
        enter-to="opacity-100 scale-100"
        leave="duration-200 ease-in transform scale-95"
        leave-from="opacity-100 scale-100"
        leave-to="opacity-0 scale-95"
        >
        <Dialog
         :open="isOpen" @close="setIsOpen" class="relative z-50">
        <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div class="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel 
          class="bg-white h-[500px] w-full xl:h-[50%] xl:w-[30%] rounded-3xl flex flex-col gap-16 py-5 xl:py-0 justify-center items-center">
            <div class="flex flex-col gap-5">
              <svg v-if="success" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
              <div v-if="!success" class="animation-ctn">
                <div class="icon icon--order-success svg mx-auto flex justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 180">  
                        <g fill="none" stroke="#F44812" stroke-width="2"> 
                          <circle cx="77" cy="77" r="72" style="stroke-dasharray:480px, 480px; stroke-dashoffset: 960px;"></circle>
                          <circle id="colored" fill="#F44812" cx="77" cy="77" r="72" style="stroke-dasharray:480px, 480px; stroke-dashoffset: 960px;"></circle>
                          <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8  112.2,77.8 " style="stroke-dasharray:100px, 100px; stroke-dashoffset: 200px;"/>   
                        </g> 
                      </svg>
                </div>
              </div>
              <DialogTitle class="text-custom-gray font-bold sm:text-2xl xl:text-3xl text-center">{{title}}</DialogTitle>
            </div>
            <button class="py-4 px-10 bg-custom-blue text-white font-semibold rounded-xl outline-none hover:scale-105 transition duration-300 ease-in-out" @click="isOpen = false">اغلاق</button>
          </DialogPanel>
        </div>
  </Dialog>
</TransitionRoot>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import {
    TransitionRoot,
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
  } from '@headlessui/vue'
  export default {
    components: {Dialog, DialogPanel, DialogTitle, DialogDescription, TransitionRoot},
    setup() {
      const email = ref('');
      const subject = ref('');
      const content = ref('');
      const isOpen = ref(false)
      const message = ref('مرحبا');
      const title = ref('تم الارسال بنجاح!');
      const success = ref(true);
      function setIsOpen(value) {
        isOpen.value = value
      }
      const sendEmail = async () => {
        if (!email.value || !subject.value || !content.value) {
        return (() => {
          title.value = 'تحقق من المدخلات';
          success.value = false
          setIsOpen(true);
          })();
        }
        const response = await fetch('https://zoom-email-service.onrender.com/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email.value,
            subject: subject.value,
            content: content.value
          })
        });
        if (response.ok) {
          title.value = 'تم الارسال بنجاح!'
          success.value = true
          setIsOpen(true);
        } else {
          title.value = 'فشل الإرسال حاول مرة اخرى'
          success.value = false
          setIsOpen(true);
        }
      };
      return {
        email,
        subject,
        content,
        sendEmail,
        message,
        title,
        setIsOpen,
        isOpen,
        success
      }
    }
  }
  </script>

  <style>
  .half-border-bottom-centered::before {
    content: "";
    width: 150px;
    height: 1px;
    position: absolute;
    bottom: 0;
    display: block;
    background-color: #5A5A5A;
    margin: 0 auto;
}
.svg svg {
  width: 120px;
    height: 125px;
    margin: 0 auto;
}
.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    width: 100%;
    height: 100%;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}

.animation-ctn{
  text-align:center;
  margin-top:5em;
}

	@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 0px
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px
   
    }

    100% {
        stroke-dashoffset: 960px;
      
    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px 
    }

    100% {
        stroke-dashoffset: 960px
    }
}

@keyframes colored-circle { 
    0% {
        opacity:0
    }

    100% {
        opacity:100
    }
}

.inlinesvg .svg svg {
    display: inline
}

.icon--order-success svg polyline {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards
}

.icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon--order-success svg circle#colored {
    -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
    animation: colored-circle 0.6s ease-in-out 0.7s backwards;
} 

  </style>