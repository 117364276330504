<template>
  <Navbar />
  <main class="mx-auto max-w-[1607px]">
   
  </main>
  <router-view/>
  <Footer />
</template>

<script>
import Footer from './components/Footer.vue'
import Navbar from './components/Navbar.vue'


export default {
  components: {
    Navbar,
    Footer
  },

    }


</script>

<style>

</style>
