<template>
    <div>
        <h1 
        v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 25,
                  },
                }"
        class="text-4xl sm:text-5xl xl:text-6xl font-semibold py-5 text-custom-gray half-border-bottom relative">
            آراء عملائنا الكرام
        </h1>
        <div class="w-full flex pt-10">
            <swiper
            :centeredSlides:="true"
            :grabCursor="true"
            :loop= "true"
            :breakpoints="{
            0: {
                slidesPerView: 1,
                centeredSlides: true
                },
            980: {
                slidesPerView: 2,
                spaceBetween: 0,
                centeredSlides: true
            },

                1480: {
                slidesPerView: 3,
                spaceBetween: 20,
                centeredSlides: false
                }
            }"
            :speed= "1000"
            :autoplay = "{
            delay: 2500,
            disableOnInteraction: false,
            }"
            :modules="modules"
            class="mySwiper"
            v-motion
                :initial="{
                  opacity: 0,
                }"
                :visible="{
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 80,
                  },
                }"
        >
            <swiper-slide v-for="(item, index) in ratings" :key="index" class="flex justify-center">
                <div class="flex flex-col gap-8 w-[470px] h-[480px] max-h-[480px] bg-white shadow-lg border border-gray-200 rounded-3xl">
                <div class="flex items-center h-52 max-h-52">
                    <img class="w-24 object-none" src="@/assets/images/male_avatar.png" alt="pexles">
                </div>
                    <div class="h-full px-4">
                        <div class="flex flex-col gap-5 items-center">
                            <h3 class="text-2xl font-semibold">{{item.name}}</h3>
                            <p class="text-lg text-custom-gray text-center">
                                {{ item.message }}                
                            </p>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        </div>
    </div>
  </template>
  
  <script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/free-mode';
  import 'swiper/css/pagination';

  import sanity from "../../studio/sanity";
  const query = `*[_type == "ratings"] {
    name,
    message
  }
  `;
  // import required modules
  import { FreeMode, Pagination, Autoplay } from 'swiper';
  import { ref, onMounted } from 'vue';
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {

  const ratings = ref([]);

  const fetchRatings = async () => {
    try {
      const results = await sanity.fetch(query);
      ratings.value = results;
    } catch (error) {
      console.error(error);
    }
  };
  onMounted(() =>{
    fetchRatings()
  })

      return {
        modules: [FreeMode, Pagination, Autoplay],
        ratings
      };
    },
  };
</script>

  
  <style scoped>
  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
  </style>