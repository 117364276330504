<template>
    <div>
        <h1 
        v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 25,
                  },
                }"
        class="text-4xl sm:text-5xl xl:text-6xl font-semibold py-5 text-custom-gray half-border-bottom relative">
            البرومو          
        </h1>
        <div class="flex flex-col gap-10">
          <div
          v-motion
                  :initial="{
                    y: 50,
                    opacity: 0,
                  }"
                  :visible="{
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: 'spring',
                      damping: 25,
                    },
                  }"
          class="pt-10">
          <!-- h-[250px] lg:h-[700px]  -->
              <iframe
                  class="px-0 lg:px-3 w-full aspect-video lazyload"
                  :src="`https://www.youtube.com/embed/${data.link}`" 
                  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                  allowfullscreen
                  title="برومو تغطيتنا لحفلات الزواج"
                  >
              </iframe>
          </div>
          <router-link to="/videos" class="flex flex-row items-center gap-3 text-white bg-custom-blue self-start py-3 px-6 rounded-xl mx-auto text-xl">
          المزيد
          <img src="@/assets/icons/arrowLeftIcon.svg" />
     </router-link>
        </div>
    </div>
  </template>
  
  <script setup>
   import { ref, onMounted } from "vue";
import sanity from "../../studio/sanity";
   const query = `*[_type == "videos"] {
    link,
    title,
    category
  }
  `;
  
  let data = ref([]);

  const fetchData = async() => {
  try {
    await sanity.fetch(query).then(fetchedVideos => {
        const processedVideos = fetchedVideos.map(video => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = video.link.match(regExp);
        if (match) {
            return {
              ...video,
              link: match[2],
            };
        } else {
            return video;
        }
      });
      data.value = processedVideos[0];
    });
  } catch (err) {
    console.log('ERRORR', err);
  }
};
onMounted(() => {
      fetchData();
});

 

  </script>
  
  <style>
  
  </style>