<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide, } from "swiper/vue";
  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/effect-coverflow";
  import "swiper/css/pagination";
  // import required modules
  import { Pagination, Autoplay, Lazy} from "swiper";
  import sanity from "../../studio/sanity";
  import imageUrlBuilder from "@sanity/image-url";
  import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
  const imageBuilder = imageUrlBuilder(sanity);
  const query = `*[_type == "latest"] {
    _id,
    title,
    image,
  }
  `;
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      let images = ref([])
      const fetchData = async() => {
  try {
    await sanity.fetch(query).then(photos => {
      photos.forEach(photo => {
        const title = photo.title || '';
        photo.image.forEach(img => {
          images.value.push({
            title: title,
            uri: urlFor(img.asset._ref).url(),
          });
        });
      });
      
    });
  } catch (err) {
    console.log('ERRORR', err);
    // loading.value = false;
  }
};
onMounted(() => {
      fetchData();
});
    function urlFor(source) {
    return imageBuilder.image(source)
  }
    const visibleRef = ref(false)
    const indexRef = ref(0)

    const showImg = (index) => {
            indexRef.value = index -1
            visibleRef.value = true
      }
      const onHide = () => visibleRef.value = false
      return {
        modules: [Pagination, Autoplay, Lazy],
        urlFor,
        images, 
        indexRef,
        showImg,
        visibleRef,
        onHide,
      };
    },
  };
  </script>
<template>
  <div>
    <swiper
    dir="rtl"
    :centeredSlides:="true"
    :grabCursor="true"
    :loop= "true"
    :breakpoints="{
      0: {
          slidesPerView: 1,
          centeredSlides: true
        },
      980: {
            slidesPerView: 2,
            spaceBetween: 0,
            centeredSlides: true
          },
      1480: {
            slidesPerView: 3,
            spaceBetween: 20,
            centeredSlides: false
          }
      }"
      :pagination="{
          dynamicBullets: true,
          }"
            :speed= "1000"
            :autoplay = "{
              delay: 5000,
              disableOnInteraction: false,
              }"
              :modules="modules"
              class="mySwiper"
              lazy
    >
      <swiper-slide
      v-for="(item, index) in images"
      :key="item._id"
      :virtualIndex="index"
      class="w-[450px] h-[400px] xl:w-[600px] xl:h-[600px] object-fit p-5 rounded-3xl"
      @click="() => showImg(index)"
      >
        <img
        class="object-cover swiper-lazy rounded-3xl"
        src="../assets/images/blankRectangle.png"
        :data-src="item.uri"
          />
        </swiper-slide>
    </swiper>
    <vue-easy-lightbox :visible="visibleRef" :imgs="images.map(item => item.uri)" :index="indexRef +1" @hide="onHide"></vue-easy-lightbox>
  </div>
  <!-- <div class="w-full flex flex-col justify-center">
    <img v-for="item in images" class="w-40" :key="item.title" :src="urlFor(item.image).url()" />
  </div> -->
</template>
  <style>
  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
</style>