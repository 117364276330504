import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Photos from '../views/PhotosView.vue'
import Videos from '../views/VideosView.vue'
import Ratings from '../views/RatingsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/photos',
    name: 'photos',
    component: Photos
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos
  },
  {
    path: '/ratings',
    name: 'ratings',
    component: Ratings
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      console.log("moving to top of the page");
      window.scrollTo(0, 0);
    }
 }
})
export default router
