<template>
  <RatingsForm />
</template>

<script>
import RatingsForm from '@/components/RatingsForm.vue'

export default{
  components: { RatingsForm },

}
</script>

<style>

</style>