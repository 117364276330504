<template>
  <div>
          <h1 
          v-motion
                  :initial="{
                    x: 100,
                    opacity: 0,
                  }"
                  :visible="{
                    x: 0,
                    opacity: 1,
                    transition: {
                      type: 'spring',
                      damping: 25,
                    },
                  }"
          class="text-4xl sm:text-5xl xl:text-6xl font-semibold py-5 text-custom-gray half-border-bottom relative">
            الأفراح
          </h1>
      <div class="flex flex-col gap-10">
        <div 
        v-motion
          :initial="{
            opacity: 0,
          }"
          :visible="{
          opacity: 1,
          transition: {
          type: 'spring',
          damping: 35,
           },
        }"
        class="grid xs:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-items-center lg:place-items-between gap-10 lg:gap-5 pt-10">
          <div
            v-for="item in images.filter(item => item.category.includes('افراح')).slice(0,3)"
            :key="item._id"
            class="flex flex-col gap-5 w-full max-w-[500px] h-[480px] max-h-[480px] bg-white shadow-2xl rounded-xl">
            <img class="h-[290px] max-h-[290px] object-cover rounded-t-xl" :src="item.uri" alt="pexles">
            <div class="flex flex-col h-full justify-between px-4">
              <div class="">
                <h3 class="text-2xl font-semibold">{{ item.title }}</h3>
              </div>
              <div class="flex flex-col h-[50px]">
                <hr class="block" />
                <p class="text-base text-custom-gray my-auto">
                  {{item.createdAt}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/photos" class="flex flex-row items-center gap-3 text-white bg-custom-blue self-start py-3 px-6 rounded-xl mx-auto text-xl">
          المزيد
          <img src="@/assets/icons/arrowLeftIcon.svg" />
     </router-link>
      </div>
  </div>
    </template>
    
    <script setup>
    import sanity from "../../studio/sanity";
    import imageUrlBuilder from "@sanity/image-url";
    import { ref } from "@vue/reactivity";
    import { onMounted } from "@vue/runtime-core";
    const imageBuilder = imageUrlBuilder(sanity);
  
    const query = `*[_type == "photos"] {
      image,
      title, 
      _createdAt,
      category-> {
        title
      }
    }`;
  
    function urlFor(source) {
      return imageBuilder.image(source)
    }
    let images = ref([])
    const loading = ref(true);
    const fetchData = async() => {

      try {
        await sanity.fetch(query).then(photos => {
        // sort the photos array in descending order of _createdAt
        photos.sort((a, b) => new Date(b._createdAt) - new Date(a._createdAt));
          // only take the first 3 items from the sorted array
          const sortedAndFilteredPhotos = photos.slice(0, 3);

          // map each photo to the desired format
          const formattedPhotos = sortedAndFilteredPhotos.map(photo => {
            const title = photo.title || '';
            const category = photo.category.title || '';
            const createdAt = photo._createdAt || '';
            const date = new Date(createdAt);
            const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;

            photo.image.forEach(img => {
              images.value.push({
                title: title,
                uri: urlFor(img.asset._ref).url(),
                category: category,
                createdAt: formattedDate
              });
        });
          });
             // assign the formatted photos to images.value
            images.value = formattedPhotos.filter(item => item.category.includes('افراح'));
            loading.value = false;
          });
      } catch (err) {
    // console.log('ERRORR', err);
    loading.value = false;
    }
    };
      onMounted(() => {
            fetchData();
      });
  </script>
    
    <style>
    
    </style>