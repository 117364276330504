<template>
  <section class="bg h-[750px] lg:h-[1000px]">
    <div class="flex flex-col justify-center items-center gap-16 lg:gap-24 pt-14">
      <img 
      v-motion
      :initial="{
        scale: 0,
      }"
      :enter="{
        scale: 1,
        transition: {
          damping: 25,
        },
  }" class="w-[300px] lg:w-[400px] lg:pt-10" src="@/assets/logos/zoomStudio.png" alt="ZoomStudio Logo">
      <div class="flex flex-col items-center gap-5 lg:gap-10">
        <h1 
        v-motion
        :initial="{
          x: 100,
          opacity: 0,
        }"
        :enter="{
          x: 0,
          opacity: 1,
          transition: {
            type: 'spring',
            damping: 25,
          },
         }" 
         class="text-2xl lg:text-5xl text-white text-center font-semibold">
         قم بحجز جلسة تصوير احترافية الان
        </h1>
        <a v-motion
        :initial="{
          x: -100,
          opacity: 0,
        }"
        :enter="{
          x: 0,
          opacity: 1,
          transition: {
            type: 'spring',
            damping: 25,
          },
         }"  class="bg-white text-xl rounded-xl text-black py-2 px-10 lg:py-3 lg:px-14" href="#form">تواصل معنا</a>
      </div>
    </div>
  </section>
  <section id="recent" class="w-full pt-0">
    <h1 
    v-motion
      :initial="{
        x: 100,
        opacity: 0,
      }"
        :visible="{
          x: 0,
          opacity: 1,
          transition: {
          type: 'spring',
          damping: 25,
        },
      }"
    class="text-4xl xl:pt-24 px-14 sm:text-5xl xl:text-6xl font-semibold py-5 text-custom-gray half-border-bottom relative">أحدث الصور</h1>
    <HeaderImages />
  </section>
  <section class="pt-20 xl:pt-48 max-w-[1478px] mx-auto px-5 xl:px-3">
    <div class="grid sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-4 place-items-center gap-4"> <!--grid-rows-1 lg:grid-rows-1-->
            <div class="hidden xl:flex flex-col lg:flex-row justify-center lg:justify-end gap-9 col-span-1 lg:col-span-3 order-1 w-full blob">
                <div  v-motion
      :initial="{
        scale: 0,
        opacity: 0
      }"
      :visible="{
        scale: 1,
        opacity: 1,
        transition: {
          damping: 25,
        },
  }" class="flex flex-col gap-5 lg:pt-[180px]">
                  <div class="bg-white flex flex-col items-center gap-14 p-10 w-80 h-80 rounded-3xl shadow-xl border border-gray-100">
                     <div class="relative card h-[80px] w-[144.08px] mx-auto">
                      <img class="center w-20" src="@/assets/images/saudiIcon.svg" alt="Saudi Icon">
                     </div>
                     <div class="flex flex-col items-center gap-5">
                       <h4 class="text-2xl">
                        أفراحكم
                       </h4>
                       <p class="text-lg text-custom-gray">
                        نوثق فرحتكم لتبقى تفاصيلها معكم.
                       </p>
                     </div>
                  </div>
                </div>
                <div class="flex flex-col gap-5">
                  <div   v-motion
      :initial="{
        scale: 0,
        opacity: 0
      }"
      :visible="{
        scale: 1,
        opacity: 1,
        transition: {
          damping: 30,
        },
  }"  class="bg-white flex flex-col items-center gap-14 p-10 w-80 h-80 rounded-3xl shadow-xl border border-gray-100">
                     <div class="relative card h-[80px] w-[144.08px] mx-auto">
                      <img class="center w-20 h-36" src="@/assets/images/conference.svg" alt="Saudi Icon">
                     </div>
                     <div class="flex flex-col items-center gap-5">
                       <h4 class="text-2xl">
                        المناسبات الرسمية
                       </h4>
                       <p class="text-lg text-custom-gray text-center">
                        لحظات سعيدة نخلدها لكم بعدستنا.                
                      </p>
                     </div>
                  </div>
                  <div   v-motion
      :initial="{
        scale: 0,
        opacity: 0
      }"
      :visible="{
        scale: 1,
        opacity: 1,
        transition: {
          damping: 35,
        },
  }"  class="bg-white flex flex-col items-center gap-14 p-10 w-80 h-80 rounded-3xl shadow-xl border border-gray-100">
                     <div class="relative card h-[80px] w-[144.08px] mx-auto">
                      <img class="center w-20" src="@/assets/images/videographer.svg" alt="Saudi Icon">
                     </div>
                     <div class="flex flex-col items-center gap-5">
                       <h4 class="text-2xl">
                        الفيديو
                       </h4>
                       <p class="text-lg text-custom-gray text-center">
                        تصوير فيديو للاحتفالات او المناسبات بدقة عالية و طرق احترافية.               
                      </p>
                     </div>
                  </div>
                </div>
                <div   v-motion
      :initial="{
        scale: 0,
        opacity: 0,
      }"
      :visible="{
        scale: 1,
        opacity: 1,
        transition: {
          damping: 40,
        },
  }"  class="flex flex-col gap-5 lg:pt-[180px]">
                  <div class="bg-white flex flex-col items-center gap-14 p-10 w-80 h-80 rounded-3xl shadow-xl border border-gray-100">
                     <div class="relative card h-[80px] w-[144.08px] mx-auto">
                      <img class="center w-36" src="@/assets/images/drone.svg" alt="Saudi Icon">
                     </div>
                     <div class="flex flex-col items-center gap-5">
                       <h4 class="text-2xl">
                        التصوير الجوي
                       </h4>
                       <p class="text-lg text-custom-gray text-center">
                        التقاط صور و فيديو في الجو بواسطة الدرون.
                       </p>
                     </div>
                  </div>
                  <div  v-motion
      :initial="{
        scale: 0,
        opacity: 0,
      }"
      :visible="{
        scale: 1,
        opacity: 1,
        transition: {
          damping: 45,
        },
  }"  class="bg-white flex flex-col items-center gap-14 p-10 w-80 h-80 rounded-3xl shadow-xl border border-gray-100">
                     <div class="relative card h-[80px] w-[144.08px] mx-auto">
                      <img class="center w-28" src="@/assets/images/invitation.svg" alt="Saudi Icon">
                     </div>
                     <div class="flex flex-col items-center gap-5">
                       <h4 class="text-2xl">
                        الدعوات الإلكترونية
                       </h4>
                       <p class="text-lg text-custom-gray text-center">
                        تصميم جميع انواع الدعوات بتصاميم مختلفة.
                       </p>
                     </div>
                  </div>
                </div>
            </div>
            <div class="grid xl:hidden grid-cols-1 md:grid-cols-2 gap-5 order-1">
              <div 
              v-motion
              :initial="{
              scale: 0,
              opacity: 0,
            }"
            :visible="{
              scale: 1,
              opacity: 1,
              transition: {
                damping: 25,
              },
             }" 
              class="bg-white flex flex-col items-center gap-14 p-10 w-full h-80 rounded-3xl shadow-xl border border-gray-100">
                    <div class="relative card h-[80px] w-[144.08px] mx-auto">
                      <img class="center w-20" src="@/assets/images/saudiIcon.svg" alt="Saudi Icon">
                     </div>
                     <div class="flex flex-col items-center gap-5">
                       <h4 class="text-2xl">
                        أفراحكم
                       </h4>
                       <p class="text-lg text-custom-gray">
                        نوثق فرحتكم لتبقى تفاصيلها معكم.
                       </p>
                     </div>
              </div>
            <div 
            v-motion
            :initial="{
            scale: 0,
            opacity: 0,
          }"
          :visible="{
            scale: 1,
            opacity: 1,
            transition: {
              damping: 30,
            },
          }" 
           class="bg-white flex flex-col items-center gap-14 p-10 w-full h-80 rounded-3xl shadow-xl border border-gray-100">
                     <div class="relative card h-[80px] w-[144.08px] mx-auto">
                      <img class="center w-20 h-36" src="@/assets/images/conference.svg" alt="Saudi Icon">
                     </div>
                     <div class="flex flex-col items-center gap-5">
                       <h4 class="text-2xl">
                        المناسبات الرسمية
                       </h4>
                       <p class="text-lg text-custom-gray text-center">
                        لحظات سعيدة نخلدها لكم بعدستنا.                
                      </p>
                     </div>
              </div>
        <div 
        v-motion
        :initial="{
        scale: 0,
        opacity: 0,
      }"
      :visible="{
        scale: 1,
        opacity: 1,
        transition: {
          damping: 35,
        },
  }" class="bg-white flex flex-col items-center gap-14 p-10 w-full h-80 rounded-3xl shadow-xl border border-gray-100">
                     <div class="relative card h-[80px] w-[144.08px] mx-auto">
                      <img class="center w-20" src="@/assets/images/videographer.svg" alt="Saudi Icon">
                     </div>
                     <div class="flex flex-col items-center gap-5">
                       <h4 class="text-2xl">
                        الفيديو
                       </h4>
                       <p class="text-lg text-custom-gray text-center">
                        تصوير فيديو للاحتفالات او المناسبات بدقة عالية و طرق احترافية.               
                      </p>
                     </div>
              </div>
          <div 
              v-motion
              :initial="{
              scale: 0,
              opacity: 0,
            }"
            :visible="{
              scale: 1,
              opacity: 1,
              transition: {
                damping: 40,
              },
            }" 
            class="bg-white flex flex-col items-center gap-14 p-10 w-full h-80 rounded-3xl shadow-xl border border-gray-100">
                      <div class="relative card h-[80px] w-[144.08px] mx-auto">
                        <img class="center w-36" src="@/assets/images/drone.svg" alt="Saudi Icon">
                      </div>
                      <div class="flex flex-col items-center gap-5">
                        <h4 class="text-2xl">
                          التصوير الجوي
                        </h4>
                        <p class="text-lg text-custom-gray text-center">
                          التقاط صور و فيديو في الجو بواسطة الدرون
                        </p>
                      </div>
          </div>
        <div 
        v-motion
        :initial="{
        scale: 0,
        opacity: 0,
        }"
        :visible="{
          scale: 1,
          opacity: 1,
          transition: {
            damping: 45,
          },
          }" 
           class="bg-white flex flex-col items-center gap-14 p-10 w-full h-80 rounded-3xl shadow-xl border border-gray-100">
                     <div class="relative card h-[80px] w-[144.08px] mx-auto">
                      <img class="center w-28" src="@/assets/images/invitation.svg" alt="Saudi Icon">
                     </div>
                     <div class="flex flex-col items-center gap-5">
                       <h4 class="text-2xl">
                        الدعوات الإلكترونية
                       </h4>
                       <p class="text-lg text-custom-gray text-center">
                        تصميم جميع انواع الدعوات بتصاميم مختلفة.
                       </p>
                     </div>
              </div>
            </div>
            <div class="col-span-1 place-self-center lg:place-self-start flex flex-col gap-5 pt-20 xl:pt-36 w-full">
                <h2 
                v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 25,
                  },
                }"
                class="text-4xl text-custom-gray half-border-bottom relative py-5">بعض من خدماتنا</h2>
                <router-link to="/" class="hidden xl:block bg-custom-blue self-start text-xl rounded-xl text-white py-3 px-14" href="#"
                v-motion
                :initial="{
                  y: 100,
                  opacity: 0,
                }"
                :visible="{
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 25,
                  },
                }"
                >
               عرض الأعمال
              </router-link>
            </div>
        </div>
  </section>

  <section class="max-w-[1478px] mx-auto px-5 xl:px-3">
  <Weddings class="pt-20 xl:pt-48" />
  <SpecialOccasions class="pt-20 xl:pt-48" />
  <Promo class="pt-20 xl:pt-48" />
  <WorkedWith class="pt-20 xl:pt-48" />
  <CustomerRatings class="pt-20 xl:pt-48" />
  <Form class="pt-20 xl:pt-48" />
</section>
</template>

<script>
import HeaderImages from '@/components/HeaderImages.vue';
import sanity from "../../studio/sanity";
import imageUrlBuilder from "@sanity/image-url";
import Weddings from '@/components/Weddings.vue';
import SpecialOccasions from '@/components/SpecialOccasions.vue';
import Promo from '@/components/Promo.vue'
import WorkedWith from '@/components/WorkedWith.vue';
import CustomerRatings from '@/components/CustomerRatings.vue';
import Form from '@/components/Form.vue';

export default {
components: {
    HeaderImages,
    Weddings,
    SpecialOccasions,
    Promo,
    WorkedWith,
    CustomerRatings,
    Form,
},

  setup() {
  
  }
}
</script>

<style>
.social-icons a {
  transition: transform 300ms ease-in-out;
}
.social-icons a:hover {
  transform: scale(1.1);
}
.bg {
  background-image: url('../assets/images/bgBlue1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.half-border-bottom::before {
    content: "";
    width: 125px;
    height: 1px;
    position: absolute;
    bottom: 0;
    display: block;
    background-color: #5A5A5A;
    margin: 0 auto;
}
.blob {
  background-image: url('../assets/images/blueBlob.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 80% 80%;
}
.card {
  background-image: url('../assets/images/cardEllipse.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.center {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}
</style>