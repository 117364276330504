<template>
    <div>
        <h1
        v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 25,
                  },
                }"
        class="text-4xl sm:text-5xl xl:text-6xl font-semibold py-5 text-custom-gray half-border-bottom relative">
            عملنا معهم          
        </h1>
        <div
        v-motion
                :initial="{
                  opacity: 0,
                }"
                :visible="{
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    damping: 100,
                  },
                }"
        class="grid grid-cols-3 lg:grid-cols-5 place-items-center gap-3 pt-10">
            <img v-for="(item, index) in images" :key="index" class="w-36 h-36 lg:w-50 lg:h-50 object-contain" :src="item.uri" alt="company img">
        </div>
    </div>
  </template>
  
  <script setup>
  import sanity from "../../studio/sanity";
  import imageUrlBuilder from "@sanity/image-url";
  import { ref } from "@vue/reactivity";
  import { onMounted } from "@vue/runtime-core";
  const imageBuilder = imageUrlBuilder(sanity);

  const query = `*[_type == "clients"] {
    title,
    image
  }`;

  function urlFor(source) {
    return imageBuilder.image(source)
  }
  let images = ref([])
  const fetchData = async() => {
    try {
      await sanity.fetch(query).then(photos => {
        // map each photo to the desired format
        photos.map(photo => {
          const title = photo.title || '';
          photo.image.forEach(img => {
            images.value.push({
              title: title,
              uri: urlFor(img.asset._ref).url(),
            });
      });
        });
    });
    } catch (err) {
  // console.log('ERRORR', err);
  // loading.value = false;
  }
  };
    onMounted(() => {
          fetchData();
    });
</script>
  
  <style>
  
  </style>