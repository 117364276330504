<template>
  <section class="flex flex-col gap-10 justify-center items-center max-w-[1607px] mx-auto pt-20 px-5 xl:px-3">
    <h1 class="flex justify-center text-4xl sm:text-5xl xl:text-6xl font-semibold py-5 text-custom-gray half-border-bottom-centered relative">
        جميع المقاطع
    </h1>
    <form class="pt-10 w-full xl:w-[500px] max-w-[500px]">   
        <label for="default-search" class="mb-2 text-sm font-medium sr-only text-white">Search</label>
        <div class="relative">
            <div class="absolute inset-y-0 left-20 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </div>
            <button type="submit" class="text-white absolute left-2.5 bottom-2.5 bg-custom-blue hover:bg-custom-blue/90 focus:ring-1 focus:outline-none focus:ring-custom-blue font-medium rounded-lg text-sm px-4 py-2">Search</button>
            <input v-model="filterItems" type="search" id="default-search" class="block w-full p-4 pl-10 text-sm text-custom-gray border border-gray-300 outline-none rounded-lg bg-gray-50 focus:ring-custom-blue focus:border-custom-blue" placeholder="ابحث عن المقطع" required>
        </div>
    </form>
    <div v-if="!filterItems.length" class="grid xs:grid-cols-2 xl:grid-cols-3 gap-5 place-items-center w-full">
      <div class="col-span-1 pic" v-for="(item, index) in currentItems" :key="index">
        <iframe 
            width="800" height="280"
            class="w-full aspect-video lazyload" 
            :src="`https://www.youtube.com/embed/${item.link}`" 
            frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
            allowfullscreen
            :title="item.title"
        >
        </iframe>
      </div>
    </div> 
   <!-- DISPLAY FILTERED IMAGES -->
   <div v-if="filterItems.length" class="grid xs:grid-cols-2 xl:grid-cols-3 gap-5 place-items-center w-full bg-blue-300">
      <div class="col-span-1" v-for="(item, index) in currentItems.filter(item => (item.title || item.category).includes(filterItems))" :key="index">
        <iframe 
            width="800" height="280"
            class="w-full aspect-video lazyload" 
            :src="`https://www.youtube.com/embed/${item.link}`" 
            frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
            allowfullscreen
            :title="item.title"
        >
        </iframe>
      </div>
    </div> 
<!-- DISPLAY IMAGES IN GENERAL -->
    <div v-if="loading" class="w-full">
        <div class="grid xs:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-items-center w-full gap-5">
        <div class="w-full block h-[350px] border bg-custom-gray/30 rounded-lg p-4 animate-pulse">
        </div>
        <div class="w-full block h-[350px] border bg-custom-gray/30 rounded-lg p-4 animate-pulse">
        </div>
        <div class="w-full block h-[350px] border bg-custom-gray/30 rounded-lg p-4 animate-pulse">
        </div>
        <div class="w-full block h-[350px] border bg-custom-gray/30 rounded-lg p-4 animate-pulse">
        </div>
        <div class="w-full block h-[350px] border bg-custom-gray/30 rounded-lg p-4 animate-pulse">
        </div>
        <div class="w-full block h-[350px] border bg-custom-gray/30 rounded-lg p-4 animate-pulse">
        </div>
        <div class="w-full block h-[350px] border bg-custom-gray/30 rounded-lg p-4 animate-pulse">
        </div>
        <div class="w-full block h-[350px] border bg-custom-gray/30 rounded-lg p-4 animate-pulse">
        </div>
        <div class="w-full block h-[350px] border bg-custom-gray/30 rounded-lg p-4 animate-pulse">
        </div>
      </div>
    </div>
<!-- PAGINATION -->
    <div v-if="!filterItems" class="pagination">
    <button class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700" @click.prevent="previousPage" :disabled="currentPage <= 1">
      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
    </button>
    <button class="x-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700" 
    v-if="currentPage > 3" @click.prevent="goToPage(1)">
      1
    </button>
    <span 
    class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
    v-if="currentPage > 3"
    >...</span>
    <button 
    class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
    v-for="page in pagesInRange" 
    :key="page" @click.prevent="goToPage(page)" 
    :class="{ active: page === currentPage }"
    >
    {{ page }}
    </button>
    <span
    class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
     v-if="currentPage < totalPages - 2">...</span>
    <button 
    class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
    v-if="currentPage < totalPages - 2" 
    @click.prevent="goToPage(totalPages)">{{ totalPages }}</button>
    <button 
    class="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
    @click.prevent="nextPage" 
    :disabled="currentPage >= totalPages || !hasNextPage">
      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
    </button>
  </div>
  </section>
  <Form class="pt-20 xl:pt-48" />
</template>
<script>
  import 'lazysizes'
  import Pagination from '@/components/Pagination.vue'
  import Form from '@/components/Form.vue'
  import sanity from "../../studio/sanity";
  import imageUrlBuilder from "@sanity/image-url";
  import { ref, computed, onMounted } from 'vue'
  const imageBuilder = imageUrlBuilder(sanity);
  const query = `*[_type == "videos"] {
    link,
    title,
    category
  }
  `;
export default {
  components: { Pagination, Form },
  setup() {
      let videos = ref([])
      const currentPage = ref(1)
      const perPage = ref(9)
      const totalPages = computed(() => Math.ceil(videos.value.length / perPage.value))
      const currentItems = computed(() => {
      const start = (currentPage.value - 1) * perPage.value
      const end = start + perPage.value
      return videos.value.slice(start, end)
    })
    const hasNextPage = computed(() => {
      const nextPageStart = currentPage.value * perPage.value
      return nextPageStart < videos.value.length
    })
    const pagesInRange = computed(() => {
      const range = []
      const start = currentPage.value > 2 ? currentPage.value - 2 : 1
      const end = currentPage.value < totalPages.value - 1 ? currentPage.value + 2 : totalPages.value
      for (let i = start; i <= end; i++) {
        range.push(i)
      }
      return range
    })
    function previousPage() {
      currentPage.value--
    }
    function nextPage() {
      currentPage.value++
    }
    function goToPage(page) {
      currentPage.value = page
    }
    const loading = ref(true);
    const fetchData = async() => {
  try {
    await sanity.fetch(query).then(fetchedVideos => {
        const processedVideos = fetchedVideos.map(video => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = video.link.match(regExp);
        if (match) {
            return {
              ...video,
              link: match[2],
            };
        } else {
            return video;
        }
      });
      videos.value = processedVideos;
      loading.value = false;
    });
  } catch (err) {
    console.log('ERRORR', err);
    loading.value = false;
  }
};
onMounted(() => {
      fetchData();
});
let filterItems = ref('')
return {
  videos,
  currentPage,
  perPage,
  totalPages,
  currentItems,
  previousPage,
  nextPage,
  goToPage,
  pagesInRange,
  hasNextPage,
  filterItems,
  loading,
};
},
}
</script>
<style>
.pagination {
  display: flex;
  justify-content: center;
}
.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.pagination .active{
background-color: lightgray;
}
</style>